<template>
  <div></div>
</template>

<script>
import {REDIRECT_QUANTURM_LEAP} from "@/core/services/store/course/courseLevel.module";

export default {
  name: "QuaturmLeapIntro",
  mounted() {
    this.redirectQuantumleap();
  },
  methods: {
    redirectQuantumleap(item) {
      this.$store.dispatch(REDIRECT_QUANTURM_LEAP, {}).then((response) => {
        setTimeout(() => {
          window.open(response.data,'_blank')
          window.location.href = '/dashboard'
        })
      }).catch((response) => {
      })
    },
  }
}
</script>

<style scoped>

</style>